<template>
  <WsMain v-if="modelData">
    <WsBreadcrumbs :menu="_breadcrumbsItems"></WsBreadcrumbs>
    <div class="ws-content-section pb-80">
      <WsCard>
        <ValidationObserver
          ref="form"
          class="ws-content-section-observer"
        >
          <form
            ref="updateForm"
            @submit.prevent
          >
            <div class="ws-content-section pb-80">
              <WsRow class="ws-state-form">
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="fields.no"
                    v-model="modelData.no"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="fields.name"
                    v-model="modelData.name"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="fields.subtitle"
                    v-model="modelData.subtitle"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="fields.shop_classes"
                    v-model="modelData.shop_classes"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="fields.featured_classes"
                    v-model="modelData.featured_classes"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="fields.is_active"
                    v-model="modelData.is_active"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="fields.duration_switch"
                    :value="modelData.duration_switch"
                    :stateData="modelData"
                    @input="$_onInput($event,'duration_switch')"
                    @form-input="$_radioFormInput($event)"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="fields.shop_product_spec_settings"
                    :value="modelData.shop_product_spec_settings"
                    @input="$_addModelInput($event,'shop_product_spec_settings')"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="fields.shop_product_specs"
                    :stateData="modelData"
                    :specSettings="modelData.shop_product_spec_settings"
                    v-model="modelData.shop_product_specs"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="fields.cover_image"
                    v-model="modelData.cover_image"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="fields.images"
                    v-model="modelData.images"
                  ></WsState>
                </WsCol>
                <WsCol
                  cols="12"
                  :class="[`col-12`]"
                >
                  <WsState
                    v-bind="fields.description"
                    v-model="modelData.description"
                  ></WsState>
                </WsCol>
              </WsRow>
            </div>
          </form>
        </ValidationObserver>
      </WsCard>
    </div>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
import S_App_State from "@/__vue2stone_cms/service/app/state";
import model from "@/__vue2stone_cms/models/shop_product_pre_order";
export default {
  data: () => ({
    isMounted: false,
    modelData: null,
    loading: {
      submit: false,
    },
    modelName: model.modelName,
    urlModelName: model.urlModelName,
    label: model.label,
    fields: model.fields,
    state: {},
    errorMessages: {},
    titleKey: "name",
  }),
  methods: {
    $_loadingStart() {
      this.loading.submit = true;
    },
    $_loadingStop() {
      this.loading.submit = false;
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.$_loadingStart();
      try {
        let postData = { ...this.modelData };
        postData = S_App_State.getFormatedStates(this.fields, postData, true);
        postData.order_type = "pre-order";
        await this.$axios.patch(`/${this.modelName}/${this._id}`, postData);
        this.$_loadingStop();
        this.$router.push(`/${this.urlModelName}/${this._id}`);
        this.$store.dispatch("app/stopNeedSave");
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.$emit("error");
          this.errorMessages = error.response.data.message;
        }
        this.$_loadingStop();
      }
    },
    async $_fetchData() {
      this.$store.dispatch("app/startPageLoading");
      let getUrl = `/${this.modelName}/${this._id}`;
      try {
        const res = await this.$axios.get(getUrl);
        this.modelData = res.data.data;
        this.modelData.duration_switch = "infinite";
        this.state = {
          ...S_App_State.getValueFromFields(this.fields, this.modelData),
        };
        this.$emit("modelDataInit", this.modelData);
        setTimeout(() => {
          this.isMounted = true;
        }, 500);
      } catch (error) {
        alert(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_radioFormInput($event) {
      this.modelData = { ...this.modelData, ...$event };
    },
    $_onInput($event, fieldKey) {
      if (fieldKey === "duration_switch") {
        if ($event === "infinite") {
          this.modelData.start_date = null;
          this.modelData.end_date = null;
        }
      }
      this.modelData = { ...this.modelData, [fieldKey]: $event };
    },
    $_addModelInput($event, fieldKey) {
      this.modelData = { ...this.modelData, [fieldKey]: $event };
    },
  },
  computed: {
    _title() {
      if (!this.modelData) {
        return this.label;
      } else {
        return this.modelData[this.titleKey];
      }
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      _breadcrumbsItems.push({
        text: this.$t(this.label),
        to: `/${this.urlModelName}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: this.$t(this._title),
        to: `/${this.urlModelName}/${this._id}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: this.$t("edit"),
        disabled: true,
      });
      return _breadcrumbsItems;
    },
    _id() {
      return this.$route.params.id;
    },
    _specSettingItemsName() {
      if (!this.modelData || !this.modelData.shop_product_spec_settings)
        return [];
      return this.modelData.shop_product_spec_settings.map((item) => {
        return item.toString();
      });
    },
  },
  mounted() {
    this.$_fetchData();
  },
};
</script>